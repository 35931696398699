import React, { useEffect, useRef } from 'react';
import styles from './slider.module.scss';

const Slider = ({ value, setValue, min = 0, max = 100, unit = '%' }) => {
  const sliderRef = useRef();
  const percent = max > 0 ? value / max * 100 : 0;

  useEffect(() => {
    const newBG = `linear-gradient(90deg, #109cf1 ${percent}%, #D7EDFF ${percent}%)`;
    if (sliderRef.current) {
      sliderRef.current.style.background = newBG;
    }
  }, [value]);

  return (
    <div className={styles.wrapper}>
      <input
        ref={sliderRef}
        type="range"
        min={min}
        max={max}
        value={value}
        onChange={(e) => {
          setValue(Number(e.target.value));
        }}
        className={styles.slider}
      />
      <span className={styles.text}>
        {value}
        {unit}
      </span>
    </div>
  );
};
export default Slider;
