import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import _ from 'lodash';
import {
  selectConnectWalletToSubmit,
  setConnectWalletToSubmit,
  selectCurrPage,
  selectFieldSettings,
  setFieldSettings,
} from '../../../../../../store/reducers/forms';
import styles from './ConnectWalletButtonSettings.module.scss';
import settingsStyles from '../../../Components/ModalSettings/ModalSettings.module.scss';
import Info from '../../../../../../assets/icons/info_small_raw.svg';
import ModalSettings from '../../../Components/ModalSettings';
import SelectionButtons from '../../../../../../components/ui/SelectionButtons';
import Input from '../../../Components/ModalSettings/Input';
import Switch from '../../../Components/ModalSettings/Switch';
import { styleOptions, alignmentOptions } from './options';
import TextArea from '../../../Components/ModalSettings/TextArea';
import Tooltip from '../../../../../../components/ui/Tooltip';

const ConnectWalletButtonSettings = ({ activeSettings }) => {
  const dispatch = useDispatch();
  const currPage = useSelector(selectCurrPage);
  const fieldSettings = useSelector(selectFieldSettings);
  const connectWalletToSubmit = useSelector(selectConnectWalletToSubmit);
  const [text, setText] = useState('Connect Wallet');
  const [subLabel, setSubLabel] = useState('');
  const [requiresSignature, setRequiresSignature] = useState(false);
  const [signatureMessage, setSignatureMessage] = useState('Please sign the message to confirm your identity');
  const [required, setRequired] = useState(true);
  const [buttonStyle, setButtonStyle] = useState(styleOptions[0].value);
  const [align, setAlign] = useState(alignmentOptions[2].value);
  const [disconnectColor, setDisconnectColor] = useState('#f7685b');
  const [disconnectTextColor, setDisconnectTextColor] = useState('#FFFFFF');
  const debouncedUpdateStoreRef = useRef();
  const [errors, setErrors] = useState({});

  const updateStore = useCallback(async () => {
    const obj = {
      name: 'connectWallet',
      text,
      subLabel,
      type: 'connectWallet',
      buttonStyle,
      align: align === 'full' ? 'center' : align,
      fullWidth: align === 'full',
      required,
      requiresSignature,
      signatureMessage,
      disconnectColor,
      disconnectTextColor,
    };
    dispatch(setFieldSettings({ ...obj, field_id: activeSettings.field_id }));
  }, [
    text,
    subLabel,
    buttonStyle,
    align,
    required,
    requiresSignature,
    signatureMessage,
    disconnectColor,
    disconnectTextColor,
    dispatch,
    activeSettings,
  ]);

  useEffect(() => {
    const currPageSettings = fieldSettings.find((o) => o.pageName === currPage);
    if (currPageSettings?.fields?.length) {
      const config = currPageSettings.fields?.find((x) => x.field_id === activeSettings.field_id);
      if (config) {
        setText(config.text);
        setSubLabel(config.subLabel);
        setButtonStyle(config.buttonStyle);
        setAlign(config.fullWidth ? 'full' : config.align);
        setRequired(config.required);
        setRequiresSignature(config.requiresSignature);
        setSignatureMessage(config.signatureMessage);
        setDisconnectColor(config.disconnectColor);
        setDisconnectTextColor(config.disconnectTextColor);
      }
    }
  }, []);

  useEffect(() => {
    if (debouncedUpdateStoreRef.current) {
      debouncedUpdateStoreRef.current.cancel();
    }

    debouncedUpdateStoreRef.current = _.debounce(updateStore, 300);
    debouncedUpdateStoreRef.current();

    return () => {
      if (debouncedUpdateStoreRef.current) {
        debouncedUpdateStoreRef.current.cancel();
      }
    };
  }, [
    text,
    subLabel,
    buttonStyle,
    align,
    required,
    requiresSignature,
    signatureMessage,
    disconnectColor,
    disconnectTextColor,
  ]);

  useEffect(() => {
    const newErrors = { ...errors };
    if (text.length === 30) {
      newErrors.text = 'Character limit reached';
    } else {
      newErrors.text = '';
    }
    if (subLabel.length === 300) {
      newErrors.subLabel = 'Character limit reached';
    } else {
      newErrors.subLabel = '';
    }
    setErrors(newErrors);
  }, [text, subLabel]);

  return (
    <ModalSettings title="Wallet Properties">
      <Switch
        text="Required Field"
        checked={required}
        onChange={() => setRequired(!required)}
      />

      <div className={styles.wrapper}>
        <div className={settingsStyles.title}>Style</div>
        <div className={styles.button_wrapper}>
          <SelectionButtons
            buttons={styleOptions}
            selectedValue={buttonStyle}
            setSelectedValue={(value) => {
              setButtonStyle(value);
            }}
            width={84}
          />
          <SelectionButtons
            buttons={alignmentOptions}
            selectedValue={align}
            setSelectedValue={(value) => {
              setAlign(value);
            }}
            width={53}
          />
        </div>
      </div>

      <Input
        title="Label"
        value={text}
        placeholder="Type here"
        onChange={(e) => {
          setText(e.currentTarget.value);
        }}
        maxLength={30}
        error={errors.text}
      />

      <Input
        title="Sublabel"
        value={subLabel}
        placeholder="Type here"
        onChange={(e) => {
          setSubLabel(e.currentTarget.value);
        }}
        maxLength={250}
        error={errors.subLabel}
        optional
      />

      <div className={styles.signature_message}>
        <Switch
          text="Signature required"
          checked={requiresSignature}
          onChange={() => setRequiresSignature(!requiresSignature)}
        />
        <Info data-for="requiredSignature" data-tip color="#90A0B7" height="17px" width="17px" />
        <Tooltip
          id="requiredSignature"
          info="Require users to sign a message from their wallet to ensure they really own it"
        />
      </div>

      {requiresSignature && (
        <TextArea
          title="Signature message"
          value={signatureMessage}
          placeholder="Enter signature message"
          rows={5}
          onChange={(e) => {
            setSignatureMessage(e.currentTarget.value);
          }}
        />
      )}

      <Switch
        text="Use as Submit Button"
        checked={connectWalletToSubmit}
        onChange={() => dispatch(setConnectWalletToSubmit(!connectWalletToSubmit))}
      />

    </ModalSettings>
  );
};

export default ConnectWalletButtonSettings;
