import React, { useEffect, useMemo, useState } from 'react';
import ChainLogo from '..';
import styles from './ChainList.module.scss';
import uniqueId from '../../../../utils/uniqueId';
import Tooltip from '../../../ui/Tooltip';
import { supportedChains } from '../chains';

const NUMBER_BEFORE_MORE_CHAINS = 5;
const SMALL_SIZE = 22;
const NORMAL_SIZE = 28;
const SMALL_WRAPPER_WIDTH = SMALL_SIZE + (NUMBER_BEFORE_MORE_CHAINS * SMALL_SIZE * 0.8);
const NORMAL_WRAPPER_WIDTH = NORMAL_SIZE + (NUMBER_BEFORE_MORE_CHAINS * NORMAL_SIZE * 0.8);
const NON_ANALYTICS_CHAINS = ['arbitrum', 'solana', 'cosmos'];

const ChainList = ({
  chains, tooltip = '', onClick = null, small = false, square = false, analytics = false
}) => {
  const filteredChains = useMemo(() => 
    chains.filter((chain) => 
      supportedChains.hasOwnProperty(chain) && (!analytics || !NON_ANALYTICS_CHAINS.includes(chain))
    ), 
    [chains, analytics]
  );
  
  const moreChains = useMemo(() => filteredChains?.slice(NUMBER_BEFORE_MORE_CHAINS) || [], [filteredChains]);
  const moreChainsTooltipId = uniqueId("more_chains");
  const [moreChainsTooltips, setMoreChainsTooltips] = useState(null);

  const isTooLong = moreChains.length > 0 || false;

  useEffect(() => {
    const chainsData = [];
    moreChains.forEach((mc) => {
      const chainData = supportedChains[mc?.toLowerCase()];
      if (chainData) {
        chainsData.push(chainData);
      }
    })
    if (chainsData.length > 0) {
    setMoreChainsTooltips(
      <React.Fragment key={uniqueId('more-chain')}>
        {chainsData.map((cd) => (<div key={cd.tooltip}>{cd.tooltip}</div>))}
      </React.Fragment>
      );
    } else {
      setMoreChainsTooltips(null);
    }
  }, [moreChains, setMoreChainsTooltips])

  if (filteredChains.length === 0) return null;

  return (
    <div 
      className={`${styles.wrapper} ${small ? styles.small_gap : styles.normal_gap}`}
      style={{ 
        width: isTooLong ? small ? SMALL_WRAPPER_WIDTH : NORMAL_WRAPPER_WIDTH : "auto",
        height: isTooLong ? small ? SMALL_SIZE : NORMAL_SIZE : "auto",
      }}
    >
      {
        filteredChains.slice(0, NUMBER_BEFORE_MORE_CHAINS).map((chain, index) => (
          <div 
            className={styles.chain}
            style={{ 
              width: small ? SMALL_SIZE : NORMAL_SIZE,
              height: small ? SMALL_SIZE : NORMAL_SIZE,
              left: isTooLong ? (small ? SMALL_SIZE * 0.8 * index : (NORMAL_SIZE * 0.8 * index)) : "0px", 
              position: isTooLong ? 'absolute' : 'static',
              zIndex: (filteredChains.length - index)
            }}
            key={uniqueId('chain')}
          >
            <ChainLogo chain={chain} tooltip={tooltip} onClick={onClick} small={small} square={square} inList />
          </div>
        ))
      }
      {isTooLong && (
        <>
          <div 
            className={`${styles.chain} ${styles.more}`}
            style={{
              width: small ? SMALL_SIZE : NORMAL_SIZE,
              height: small ? SMALL_SIZE : NORMAL_SIZE,
              left: small ? SMALL_SIZE * 0.8 * NUMBER_BEFORE_MORE_CHAINS : (NORMAL_SIZE * 0.8 * NUMBER_BEFORE_MORE_CHAINS),
              fontSize: small ? '10px' : '12px' 
            }}
            data-for={moreChainsTooltipId}
            data-tip
          >
            +{moreChains.length}
          </div>
          <Tooltip id={moreChainsTooltipId} info={moreChainsTooltips} />
        </>
      )}
    </div>
  )
};

export default ChainList;
