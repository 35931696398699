import React, { useState, useEffect } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  setFormName,
  selectFormName,
  setFormUrl,
  selectFormUrl,
  setAllowMultipleSubmissions,
  selectAllowMultipleSubmissions,
  selectVerticalMargins,
  setVerticalMargins,
  selectHorizontalMargins,
  setHorizontalMargins,
  selectFormWidth,
  setFormWidth,
} from '../../../../../store/reducers/forms';
import ModalSettings from '../../Components/ModalSettings';
import StyleSettings from './StyleSettings';
import FontSettings from './FontSettings';
import { showErrorMessage } from '../../../../../components/base/Notifications';
import Input from '../../Components/ModalSettings/Input';
import Switch from '../../Components/ModalSettings/Switch';
import settingsStyles from '../../Components/ModalSettings/ModalSettings.module.scss';
import FormRadius from '../../Components/FormRadius';

const OverallSettings = ({ fontsData }) => {
  const dispatch = useDispatch();
  const formName = useSelector(selectFormName);
  const formUrl = useSelector(selectFormUrl);
  const verticalMargins = useSelector(selectVerticalMargins);
  const horizontalMargins = useSelector(selectHorizontalMargins);
  const formWidth = useSelector(selectFormWidth);
  const allowMultipleSubmissions = useSelector(selectAllowMultipleSubmissions);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (formName.length === 50) {
      setErrors({ ...errors, formName: 'Character limit reached' });
    } else {
      setErrors({ ...errors, formName: '' });
    }
  }, [formName]);

  return (
    <ModalSettings title="Settings">

      <Input
        title="Form name"
        value={formName}
        placeholder="Type here"
        onChange={(e) => {
          dispatch(setFormName(e.currentTarget.value));
        }}
        maxLength={50}
        error={errors.formName}
      />

      <Input
        title="Form URL"
        value={formUrl}
        prefix="wrm.link/"
        placeholder="Type here"
        onChange={(e) => {
          const userInput = e.currentTarget.value;
          if ((userInput.length <= 20 && /^[a-z0-9-]+$/.test(userInput)) || userInput === '') {
            dispatch(setFormUrl(userInput));
          } else if (userInput.length > 20) {
            showErrorMessage('URL string can be a maximum of 20 characters.');
          } else {
            showErrorMessage('Invalid URL string. Only use lower-case letters, numbers, and dashes.');
          }
        }}
        maxLength={20}
      />

      <Switch
        text="Prevent multiple submissions"
        checked={!allowMultipleSubmissions}
        onChange={() => dispatch(setAllowMultipleSubmissions(!allowMultipleSubmissions))}
      />

      <StyleSettings activeSettings />

      <FontSettings fontsData={fontsData} />

      <div className={settingsStyles.section_title}>
        Dimensions
      </div>

      <div className={settingsStyles.row}>
        <div className={settingsStyles.half_row}>
          <Input
            title="Side Margins"
            value={horizontalMargins}
            placeholder="Type here"
            suffix='px'
            onChange={(e) => {
              const newVal = e.currentTarget.value;
              if (/^\d*$/.test(newVal) && Number(newVal) <= 250) {
                dispatch(setHorizontalMargins(newVal));
                setErrors({ ...errors, horizontalMargins: '' });
              } else if (!(/^\d*$/.test(newVal))) {
                setErrors({ ...errors, horizontalMargins: 'Invalid entry' });
              } else {
                setErrors({ ...errors, horizontalMargins: '250 is the max' });
              }
            }}
            maxLength={4}
            error={errors.horizontalMargins}
          />
        </div>

        <div className={settingsStyles.half_row}>
          <Input
            title="Top/Bottom Margins"
            value={verticalMargins}
            placeholder="Type here"
            suffix='px'
            onChange={(e) => {
              const newVal = e.currentTarget.value;
              if (/^\d*$/.test(newVal) && Number(newVal) <= 1000) {
                dispatch(setVerticalMargins(newVal));
                setErrors({ ...errors, verticalMargins: '' });
              } else if (!(/^\d*$/.test(newVal))) {
                setErrors({ ...errors, verticalMargins: 'Invalid entry' });
              } else {
                setErrors({ ...errors, verticalMargins: '1000 is the max' });
              }
            }}
            maxLength={5}
            error={errors.verticalMargins}
          />
        </div>

        <div className={settingsStyles.half_row}>
          <Input
            title="Form Box Width"
            value={formWidth}
            placeholder="Type here"
            suffix='px'
            onChange={(e) => {
              const newVal = e.currentTarget.value;
              if (/^\d*$/.test(newVal)) {
                dispatch(setFormWidth(newVal));
              }
            }}
          />
        </div>



        <div className={settingsStyles.half_row}>
          <FormRadius />
        </div>
      </div>
    </ModalSettings>
  );
};

export default OverallSettings;
