import React, { useEffect } from 'react';
import Slider from '../Slider';

const SliderPercentage = ({ value, setValue, setChange }) => {
  useEffect(() => {
    setChange(true);
  }, [value])

  return <Slider value={value} setValue={setValue} min={1} />;
};
export default SliderPercentage;
