import React from 'react';
import { supportedChains } from './chains';
import Tooltip from '../../ui/Tooltip';
import uniqueId from '../../../utils/uniqueId';
import styles from './ChainLogo.module.scss';

const ChainLogo = ({
  chain, tooltip = '', onClick, small = false, medium = false, large = false, square = false, inList = false
}) => {
  let style = small
    ? styles.small
    : medium
      ? styles.medium
      : large
        ? styles.large
        : styles.normal;
  if (square) {
    style = [style, styles.square].join(' ');
  }
  const tooltipId = uniqueId(chain);

  const handleClick = () => {
    if (onClick) {
      onClick(chain);
    }
  };

  if (!chain) {
    return null;
  }

  const chainData = supportedChains[chain?.toLowerCase()];
  if (!chainData) {
    console.error(`No icon found for ${chain}`);
    return null;
  }

  return (
    <div
      role="presentation"
      className={`${onClick ? 'cursor-pointer' : ''}`}
      onClick={handleClick}
    >
      <div
        className="d-flex"
        data-for={tooltipId}
        data-tip
      >
        <chainData.icon 
          className={style}
          style={{ height: inList && chainData.sizes ? chainData.sizes[small ? 'small' : 'normal'] : undefined }}
        />
      </div>
      <Tooltip id={tooltipId} info={tooltip || chainData.tooltip} />
    </div>
  );
};

export default ChainLogo;
