import React from 'react';
import Select from 'react-select';
import { placeholderStyle } from '../../../AddSegment/Condition/Condition.constants';
import styles from './TextWithValueDropdown.module.scss';

const TextWithValueDropdown = ({ text, inputValue, onInputChange, dropDownValue, onDropdownChange, dropdownData, max = null }) => {
  const handleInputValueChange = (event) => {
    const newValue = event.target.value;
    if (/^\d*$/.test(newValue)) {
      if (max && newValue > max) {
        return;
      }
      onInputChange(newValue);
    }
  }

  const handleDropdownValueChange = (newValue) => {
    onDropdownChange(newValue.value);
  }

  
  return (
    <div className={styles.wrapper}>
      <span>{text}</span>
      <input 
        className={styles.value} 
        type="text" 
        pattern="\d*"
        inputmode="numeric"
        value={inputValue} 
        onChange={handleInputValueChange}
      />
      <Select
        styles={placeholderStyle}
        maxMenuHeight={194}
        className="w-100"
        value={dropdownData.filter((data) => data.value === dropDownValue)}
        options={dropdownData}
        onChange={handleDropdownValueChange}
        isSearchable={false}
      />
    </div>
  );
};

export default TextWithValueDropdown;
