import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setFormStyle,
  setFormImageFile,
  setBackgroundFile,
  selectFormStyle,
  selectBackgroundSize,
  selectBackgroundPosition,
  selectFormSize,
  selectFormPosition,
  setBackgroundSize,
  setBackgroundPosition,
  setFormSize,
  setFormPosition,
  selectFormLinkColor,
  setFormLinkColor,
  setFieldBackgroundColor,
  setFieldPlaceholderColor,
  setFieldBorderColor,
  setFieldLightBorders,
  selectFieldBackgroundColor,
  selectFieldPlaceholderColor,
  selectFieldBorderColor,
  selectFieldLightBorders,
  setButtonMinWidth,
  selectButtonMinWidth,
} from '../../../../../../store/reducers/forms';
import { formsApi } from '../../../../../../api/forms';
import { showErrorMessage } from '../../../../../../components/base/Notifications';
import Switch from '../../../Components/ModalSettings/Switch';
import ColorScheme from '../../../Components/ColorScheme';
import settingsStyles from '../../../Components/ModalSettings/ModalSettings.module.scss';
import ColorSelector from '../../../Components/ColorSelector';
import PictureSelector from '../../../Components/PictureSelector';
import Dropdown from '../../../Components/Dropdown';
import { coverPositions, noCoverPositions, getPosition, uncommonPositions } from './options';
import Input from '../../../Components/ModalSettings/Input';
import ButtonRadius from './ButtonRadius';

const StyleSettings = ({ activeSettings }) => {
  const dispatch = useDispatch();
  const backgroundImageRef = useRef(null);
  const formImageRef = useRef(null);
  const uploadingBackgroundImage = useRef(null);
  const formStyle = useSelector(selectFormStyle);
  const [formColor, setFormColor] = useState('#FFFFFF');
  const [textColor, setTextColor] = useState('#000000');
  const linkColor = useSelector(selectFormLinkColor);
  const [buttonColor, setButtonColor] = useState('#109CF1');
  const [buttonTextColor, setButtonTextColor] = useState('#FFFFFF');
  const [backgroundColor, setBackgroundColor] = useState('#FFFFFF');
  const [backgroundUrl, setBackgroundUrl] = useState('');
  const [fileBackgroundName, setBackgroundFileName] = useState('');
  const [formUrl, setFormUrl] = useState('');
  const [fileFormName, setFormFileName] = useState('');
  const reduxState = useSelector((state) => state.formsReducer);
  const [uploadBackgroundImage, uploadBackgroundResult] = formsApi.useUploadImageMutation();
  const [uploadFormImage, uploadFormResult] = formsApi.useUploadImageMutation();
  const backgroundPosition = useSelector(selectBackgroundPosition);
  const backgroundPositionValue = getPosition(backgroundPosition);
  const backgroundSize = useSelector(selectBackgroundSize);
  const backgroundExtend = backgroundSize === 'cover';
  const formPosition = useSelector(selectFormPosition);
  const formPositionValue = getPosition(formPosition);
  const formBackgroundSize = useSelector(selectFormSize);
  const formBackgroundExtend = formBackgroundSize === 'cover';
  const fieldBackgroundColor = useSelector(selectFieldBackgroundColor);
  const placeholderColor = useSelector(selectFieldPlaceholderColor);
  const borderColor = useSelector(selectFieldBorderColor);
  const buttonMinWidth = useSelector(selectButtonMinWidth);
  const lightBorders = useSelector(selectFieldLightBorders);
  const [errors, setErrors] = useState({});
  
  const handleColorClick = (config) => {
    setButtonColor(config.buttonColour);
    setButtonTextColor(config.buttonTextColour);
    setBackgroundColor(config.backgroundColour);

    dispatch(setFormStyle({
      formStyle: {
        ...reduxState.style.formStyle,
        colourTheme: config.color,
      },
      buttonStyle: {
        ...reduxState.style.buttonStyle,
        buttonColour: config.buttonColour,
        textColour: config.buttonTextColour,
      },
      backgroundStyle: {
        ...reduxState.style.backgroundStyle,
        backgroundColour: config.backgroundColour,
        backgroundImageUrl: '',
      },
      fieldStyle: {
        ...reduxState.style.fieldStyle,
      },
      fontStyle: {
        ...reduxState.style.fontStyle,
      },
      submitStyle: {
        ...reduxState.style.submitStyle,
      },
    }));
  };

  const handleColorChange = (color, type) => {
    switch (type) {
      case 'form':
        setFormColor(color);
        dispatch(setFormStyle({
          formStyle: {
            ...reduxState.style.formStyle,
            formColour: color,
          },
          buttonStyle: {
            ...reduxState.style.buttonStyle,
          },
          backgroundStyle: {
            ...reduxState.style.backgroundStyle,
          },
          fieldStyle: {
            ...reduxState.style.fieldStyle,
          },
          fontStyle: {
            ...reduxState.style.fontStyle,
          },
          submitStyle: {
            ...reduxState.style.submitStyle,
          },
        }));
        break;

      case 'text':
        setTextColor(color);
        dispatch(setFormStyle({
          formStyle: {
            ...reduxState.style.formStyle,
            textColour: color,
          },
          buttonStyle: {
            ...reduxState.style.buttonStyle,
          },
          backgroundStyle: {
            ...reduxState.style.backgroundStyle,
          },
          fieldStyle: {
            ...reduxState.style.fieldStyle,
          },
          fontStyle: {
            ...reduxState.style.fontStyle,
          },
          submitStyle: {
            ...reduxState.style.submitStyle,
          },
        }));
        break;

      case 'link':
        dispatch(setFormLinkColor(color));
        break;
      case 'fieldBackground':
        dispatch(setFieldBackgroundColor(color));
        break;
      case 'placeholder':
        dispatch(setFieldPlaceholderColor(color));
        break;
      case 'border':
        dispatch(setFieldBorderColor(color));
        break;

      case 'button':
        setButtonColor(color);
        dispatch(setFormStyle({
          formStyle: {
            ...reduxState.style.formStyle,
          },
          buttonStyle: {
            ...reduxState.style.buttonStyle,
            buttonColour: color,
          },
          backgroundStyle: {
            ...reduxState.style.backgroundStyle,
          },
          fieldStyle: {
            ...reduxState.style.fieldStyle,
          },
          fontStyle: {
            ...reduxState.style.fontStyle,
          },
          submitStyle: {
            ...reduxState.style.submitStyle,
          },
        }));
        break;

      case 'buttonText':
        setButtonTextColor(color);
        dispatch(setFormStyle({
          formStyle: {
            ...reduxState.style.formStyle,
          },
          buttonStyle: {
            ...reduxState.style.buttonStyle,
            textColour: color,
          },
          backgroundStyle: {
            ...reduxState.style.backgroundStyle,
          },
          fieldStyle: {
            ...reduxState.style.fieldStyle,
          },
          fontStyle: {
            ...reduxState.style.fontStyle,
          },
          submitStyle: {
            ...reduxState.style.submitStyle,
          },
        }));
        break;

      case 'background':
        setBackgroundColor(color);
        dispatch(setFormStyle({
          formStyle: {
            ...reduxState.style.formStyle,
          },
          buttonStyle: {
            ...reduxState.style.buttonStyle,
          },
          backgroundStyle: {
            ...reduxState.style.backgroundStyle,
            backgroundColour: color,
          },
          fieldStyle: {
            ...reduxState.style.fieldStyle,
          },
          fontStyle: {
            ...reduxState.style.fontStyle,
          },
          submitStyle: {
            ...reduxState.style.submitStyle,
          },
        }));
        break;

      default:
        break;
    }
  };

  const handleBackgroundFileUpload = async (event) => {
    const file = event.target.files[0];

    if (!file) return;

    if ((file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif')
      && (file.name.endsWith('.jpg') || file.name.endsWith('.jpeg') || file.name.endsWith('.png') || file.name.endsWith('.gif'))) {
      uploadingBackgroundImage.current = file;
      const formData = new FormData();
      formData.append('form_image', file);
      await uploadBackgroundImage(formData);
    } else {
      showErrorMessage('Please select a valid image file. JPG, PNG and GIF are supported.');
    }
  };

  useEffect(() => {
    if (uploadBackgroundResult.isSuccess && uploadBackgroundResult.data?.form_image) {
      setBackgroundFileName(uploadingBackgroundImage.current.name);
      setBackgroundUrl(uploadBackgroundResult.data.form_image);
      dispatch(setBackgroundFile({ fieldId: 'background_setting_image', file: uploadingBackgroundImage.current }));
      dispatch(setFormStyle({
        formStyle: {
          ...reduxState.style.formStyle,
        },
        buttonStyle: {
          ...reduxState.style.buttonStyle,
        },
        backgroundStyle: {
          ...reduxState.style.backgroundStyle,
          backgroundImageUrl: uploadBackgroundResult.data.form_image,
        },
        fieldStyle: {
          ...reduxState.style.fieldStyle,
        },
        fontStyle: {
          ...reduxState.style.fontStyle,
        },
        submitStyle: {
          ...reduxState.style.submitStyle,
        },
      }));
      uploadingBackgroundImage.current = null;
    } else if (uploadBackgroundResult.isError) {
      if (backgroundImageRef.current) {
        backgroundImageRef.current.reset();
      }
      const message = uploadBackgroundResult.error?.data?.form_image && uploadBackgroundResult.error?.data?.form_image.length > 0 ? uploadBackgroundResult.error?.data?.form_image[0] : '';
      if (message === "Upload a valid image. The file you uploaded was either not an image or a corrupted image.") {
        showErrorMessage('The file you uploaded could not be used: it is either not an image or a corrupted one.');
      } else {
        showErrorMessage('An unknown error occurred when processing your file. Please try again.');
      }
    }
  }, [uploadBackgroundResult]);

  const handleFormFileUpload = async (event) => {
    const file = event.target.files[0];

    if (!file) return;

    if ((file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif')
      && (file.name.endsWith('.jpg') || file.name.endsWith('.jpeg') || file.name.endsWith('.png') || file.name.endsWith('.gif'))) {
      uploadFormImage.current = file;
      const formData = new FormData();
      formData.append('form_image', file);
      await uploadFormImage(formData);
    } else {
      showErrorMessage('Please select a valid image file. JPG, PNG and GIF are supported.');
    }
  };

  useEffect(() => {
    if (uploadFormResult.isSuccess && uploadFormResult.data?.form_image) {
      setFormFileName(uploadFormImage.current.name);
      setFormUrl(uploadFormResult.data.form_image);
      dispatch(setFormImageFile({ fieldId: 'form_setting_image', file: uploadFormImage.current }));
      dispatch(setFormStyle({
        formStyle: {
          ...reduxState.style.formStyle,
          formImageUrl: uploadFormResult.data.form_image,
        },
        buttonStyle: {
          ...reduxState.style.buttonStyle,
        },
        backgroundStyle: {
          ...reduxState.style.backgroundStyle,
        },
        fieldStyle: {
          ...reduxState.style.fieldStyle,
        },
        fontStyle: {
          ...reduxState.style.fontStyle,
        },
        submitStyle: {
          ...reduxState.style.submitStyle,
        },
      }));
      uploadFormImage.current = null;
    } else if (uploadFormResult.isError) {
      if (formImageRef.current) {
        formImageRef.current.reset();
      }
      const message = uploadFormResult.error?.data?.form_image && uploadFormResult.error?.data?.form_image.length > 0 ? uploadFormResult.error?.data?.form_image[0] : '';
      if (message === "Upload a valid image. The file you uploaded was either not an image or a corrupted image.") {
        showErrorMessage('The file you uploaded could not be used: it is either not an image or a corrupted one.');
      } else {
        showErrorMessage('An unknown error occurred when processing your file. Please try again.');
      }
    }
  }, [uploadFormResult]);

  const removeBackgroundImage = () => {
    setBackgroundUrl('');
    setBackgroundFileName('');
    dispatch(setFormStyle({
      formStyle: {
        ...reduxState.style.formStyle,
      },
      buttonStyle: {
        ...reduxState.style.buttonStyle,
      },
      backgroundStyle: {
        ...reduxState.style.backgroundStyle,
        backgroundImageUrl: '',
      },
      fieldStyle: {
        ...reduxState.style.fieldStyle,
      },
      fontStyle: {
        ...reduxState.style.fontStyle,
      },
      submitStyle: {
        ...reduxState.style.submitStyle,
      },
    }));
  };

  const removeFormImage = () => {
    setFormUrl('');
    setFormFileName('');
    dispatch(setFormStyle({
      formStyle: {
        ...reduxState.style.formStyle,
        formImageUrl: '',
      },
      buttonStyle: {
        ...reduxState.style.buttonStyle,
      },
      backgroundStyle: {
        ...reduxState.style.backgroundStyle,
      },
      fieldStyle: {
        ...reduxState.style.fieldStyle,
      },
      fontStyle: {
        ...reduxState.style.fontStyle,
      },
      submitStyle: {
        ...reduxState.style.submitStyle,
      },
    }));
  };

  useEffect(() => {
    if (activeSettings && formStyle) {
      handleColorChange(formStyle.formStyle.formColour, 'form');
      handleColorChange(formStyle.formStyle.textColour, 'text');
      handleColorChange(formStyle.formStyle.linkColor, 'link');
      handleColorChange(formStyle.buttonStyle.buttonColour, 'button');
      handleColorChange(formStyle.buttonStyle.textColour, 'buttonText');
      handleColorChange(formStyle.backgroundStyle.backgroundColour, 'background');
      setBackgroundUrl(formStyle.backgroundStyle.backgroundImageUrl);
      setFormUrl(formStyle.formStyle.formImageUrl);
    }
  }, [activeSettings]);

  const handleFormSizeChange = () => {
    const newValue = !formBackgroundExtend;
    dispatch(setFormSize(newValue ? 'cover' : 'initial'));
    if (newValue && uncommonPositions.includes(formPosition)) {
      dispatch(setFormPosition('center'));
    }
  }

  const handleBackgroundSizeChange = () => {
    const newValue = !backgroundExtend;
    dispatch(setBackgroundSize(newValue ? 'cover' : 'initial'));
    if (newValue && uncommonPositions.includes(backgroundPosition)) {
      dispatch(setBackgroundPosition('center'));
    }
  }

  const handleFormPositionChange = (newPos) => {
    dispatch(setFormPosition(newPos.value));
  }

  const handleBackgroundPositionChange = (newPos) => {
    dispatch(setBackgroundPosition(newPos.value));
  }

  return (
    <>
      <div className={settingsStyles.section_title}>
        Style
      </div>

      <ColorScheme handleConfigChange={handleColorClick} />

      <div className={settingsStyles.section_title}>
        Form style
      </div>

      <div className={settingsStyles.row}>
        <ColorSelector
          id="form"
          title="Form Color"
          color={formColor}
          colorChange={handleColorChange}
        />

        <PictureSelector
          ref={formImageRef}
          title="Form Image"
          url={formUrl}
          fileName={fileFormName}
          uploadResult={uploadFormResult}
          handleFileUpload={handleFormFileUpload}
          removeImage={removeFormImage}
        />

        {formUrl ? (
          <>
            <Dropdown 
              title="Image Position" 
              options={formBackgroundExtend ? coverPositions : noCoverPositions}
              onChange={handleFormPositionChange}
              value={formPositionValue}
            />
            <div className={settingsStyles.extend_switch_wrapper}>
              <Switch
                text="Extend image"
                checked={formBackgroundExtend}
                onChange={handleFormSizeChange}
              />
            </div>
          </>
        ) : null}

        <ColorSelector
          id="text"
          title="Text Color"
          color={textColor}
          colorChange={handleColorChange}
        />

        <ColorSelector
          id="link"
          title="Link Color"
          color={linkColor}
          colorChange={handleColorChange}
          right
        />
      </div>

      <div className={settingsStyles.section_title}>
        Button style
      </div>

      <div className={settingsStyles.row}>
        <ColorSelector
          id="button"
          title="Button Color"
          color={buttonColor}
          colorChange={handleColorChange}
        />

        <ColorSelector
          id="buttonText"
          title="Button Text Color"
          color={buttonTextColor}
          colorChange={handleColorChange}
          right
        />

        <div className={settingsStyles.half_row}>
          <Input
            title="Button Min Width"
            value={buttonMinWidth}
            placeholder="Type here"
            suffix='px'
            onChange={(e) => {
              const newVal = e.currentTarget.value;
              if (/^\d*$/.test(newVal)) {
                setErrors({ ...errors, buttonMinWidth: '' });
                dispatch(setButtonMinWidth(newVal));
              } else {
                setErrors({ ...errors, buttonMinWidth: 'Invalid entry' });
              }
            }}
            maxLength={5}
            error={errors.buttonMinWidth}
          />
        </div>

        <div className={settingsStyles.half_row}>
          <ButtonRadius />
        </div>
      </div>

      <div className={settingsStyles.section_title}>
        Background style
      </div>

      <div className={settingsStyles.row}>
        <ColorSelector
          id="background"
          title="Background Color"
          color={backgroundColor}
          colorChange={handleColorChange}
        />

        <PictureSelector
          ref={backgroundImageRef}
          title="Background Image"
          url={backgroundUrl}
          fileName={fileBackgroundName}
          uploadResult={uploadBackgroundResult}
          handleFileUpload={handleBackgroundFileUpload}
          removeImage={removeBackgroundImage}
        />

        {backgroundUrl ? (
          <>
            <Dropdown 
              title="Image Position" 
              options={backgroundExtend ? coverPositions : noCoverPositions} 
              onChange={handleBackgroundPositionChange}
              value={backgroundPositionValue}
            />
            <div className={settingsStyles.extend_switch_wrapper}>
              <Switch
                text="Extend image"
                checked={backgroundExtend}
                onChange={handleBackgroundSizeChange}
              />
            </div>
          </>
        ) : null}
      </div>


      <div className={settingsStyles.section_title}>
        Field style
      </div>

      <div className={settingsStyles.row}>
          
        <ColorSelector
          id="fieldBackground"
          title="Background Color"
          color={fieldBackgroundColor}
          colorChange={handleColorChange}
        />

        <ColorSelector
          id="placeholder"
          title="Placeholder Color"
          color={placeholderColor}
          colorChange={handleColorChange}
          right
        />

        <ColorSelector
          id="border"
          title="Border Color"
          color={borderColor}
          colorChange={handleColorChange}
        />

        <div className={settingsStyles.extend_switch_wrapper}>
          <Switch
            text="Light Borders"
            checked={lightBorders}
            onChange={() => dispatch(setFieldLightBorders(!lightBorders))}
          />
        </div>
      </div>
    </>
  );
};

export default StyleSettings;
