import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectFormRadius, setFormRadius } from '../../../../../store/reducers/forms';
import Slider from '../../../../../components/ui/Slider';
import settingsStyles from '../../Components/ModalSettings/ModalSettings.module.scss';
import styles from './FormRadius.module.scss';

const FormRadius = () => {
  const dispatch = useDispatch();
  const formRadius = useSelector(selectFormRadius);

  return (
    <div className={styles.wrapper}>
      <div className={settingsStyles.title}>Form Corner Radius</div>
      <div className={styles.slider}>
        <Slider value={formRadius} setValue={(val) => dispatch(setFormRadius(val))} max={30} unit='px' />
      </div>
    </div>
  )
}

export default FormRadius
