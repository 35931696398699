import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigationType, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import './NFTDetails.scss';
import { coinApi } from '../../api/coin';
import {
  salesData, setTabPages, selectFilterOption,
} from '../../store/reducers/coin';
import { getUser } from '../../store/reducers/user';
import { resetSelectedAsset } from '../../store/reducers/app';
import Price from '../../assets/images/asset/FloorPrice.svg?url';
import Volume from '../../assets/images/asset/volume.svg?url';
import Holder from '../../assets/images/asset/holder.svg?url';
import Item from '../../assets/images/asset/Items.svg?url';
import { convertNumber, dividerCommaFormat, removeExcessZeros } from '../../tools/NumberConverterTool';
import { ethFormat } from '../../utils/singleAssetPage/parseData';
import InvestorProfile from './InvestorProfile';
import InvestPortfolio from './InvestorPortfolio';
import Holders from './Holders';
import SingleNFTTitle from '../../components/ui/TitleSection/Titles/SingleNFTTitle';
import Market from '../../components/ui/Market';
import Exchanges from './Exchanges';
import DappUsage from './DappUsage';
import uniqueId from '../../utils/uniqueId';
import Filters from './Filters';
import {
  getDays, getGrowth, getItems, getItemsPerHolder,
} from '../../utils/values';
import { toMaxNDecimals } from '../../utils/percent';
import { getTokenUnit } from '../../utils/chains';
import DevActivity from "./DevActivity";
import { isEmptyObject } from '../../utils/object';
import { useAiOverview } from '../../components/hooks/useAiOverview';

const DEFAULT_TAB = 'Audience Profile';

const NFTDetails = () => {
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const navType = useNavigationType();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState(null);
  const { network, name } = useParams();
  const [pages, setPages] = useState([]);
  const [titleSectionData, setTitleSectionData] = useState(null);
  const [marketData, setMarketData] = useState({});
  const filterOption = useSelector(selectFilterOption);
  const user = useSelector(getUser);
  const canDisplayAi = !isEmptyObject(user) && user.ai_explanations;

  const {
    data: nft,
    isLoading: isLoadingNFTProfile,
    isFetching: isFetchingNFTProfile,
    isSuccess: isSuccessNFTProfile,
  } = coinApi.useGetNftBasicInfoQuery({ address: name, network });

  const aiOverview = useAiOverview({
    isReady: canDisplayAi,
    filterOption,
    type: 'nft',
    address: nft?.contract_address,
    network: nft?.blockchain,
    isCustomContract: !!nft?.custom_contract
  });

  const marketAiOverview = useAiOverview({
    isReady: canDisplayAi,
    type: 'nft',
    address: name,
    network,
    isCustomContract: !!nft?.custom_contract,
    market: true
  });

  const isOrdinalsOrFlow = nft?.blockchain === 'ordinals' || nft?.blockchain === 'flow';

  const { data: ethereum } = coinApi.useGetCoinByIdQuery(
    'ethereum',
    { skip: nft === null || !(isSuccessNFTProfile && nft?.contract_type) || getTokenUnit(nft?.blockchain) === 'BTC' },
  );
  const { data: bitcoin } = coinApi.useGetCoinByIdQuery(
    'bitcoin',
    { skip: nft === null || !(isSuccessNFTProfile && nft?.contract_type) || getTokenUnit(nft?.blockchain) !== 'BTC'},
  );
  const exchangeRate = getTokenUnit(nft?.blockchain) !== 'BTC'
    ? ethereum?.market_data.current_price.usd
    : bitcoin?.market_data.current_price.usd
  const {
    currentData: statsTrading,
    isLoading: isStatsTradingLoading,
    isFetching: isStatsTradingFetching,
    status,
  } = coinApi.useGetNftStatsTradingQuery({ address: name, network, contractType: nft?.contract_type }, {
    skip: !(isSuccessNFTProfile && nft?.contract_type),
  });

  const {
    data: reach,
    isLoading: isReachLoading,
    isFetching: isReachFetching,
  } = coinApi.useReachNFTQuery({ address: name, network }, {
    skip: !(isSuccessNFTProfile && nft?.contract_type),
  });

  const {
    currentData: audienceProfileData,
    isLoading: isAudienceProfileLoading,
    isFetching: isAudienceProfileFetching,
    isSuccess: isSuccessAudienceProfile,
    refetch,
  } = coinApi.useGetNFTAudienceProfileQuery(
    { address: name, chain: network, audience: filterOption },
    { skip: !(filterOption && filterOption !== 'relevant') },
  );

  useEffect(() => {
    dispatch(salesData(null));
  }, [dispatch, name]);

  useEffect(() => {
    if (isSuccessAudienceProfile) {
      refetch();
    }
  }, [refetch, filterOption]);

  const getPage = useCallback((pageLabel) => pages.find((page) => page.label === pageLabel), [pages]);

  useEffect(() => {
    const tabName = searchParams.get('tab')?.replace('+', ' ') || DEFAULT_TAB;
    const holdersTabs = ['current', 'relevant'];
    const isHoldersTab = tabName === 'Holders';
    const shouldSwitchToHolders = holdersTabs.includes(filterOption) && tabName === 'List';
    const shouldSwitchToList = !holdersTabs.includes(filterOption) && isHoldersTab;
    if (shouldSwitchToHolders || shouldSwitchToList) {
      const switchLabel = shouldSwitchToHolders ? 'Holders' : 'List';
      setSelectedTab(getPage(switchLabel));
      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        page: '1',
        tab: switchLabel,
      });
    }
    setSelectedTab(pages.length ? getPage(tabName) : null);
  }, [searchParams, pages, filterOption]);

  useEffect(() => {
    if (status === 'fulfilled' && statsTrading) {
      dispatch(salesData(statsTrading));
    }
    if (status === 'fulfilled' && statsTrading === null) {
      dispatch(salesData([]));
    }
  }, [dispatch, statsTrading, status]);

  useEffect(() => {
    if (isLoadingNFTProfile || isFetchingNFTProfile) {
      setTitleSectionData(null);
    }
    if (nft && nft.contract_type) {
      const floorPrice = (nftData) => {
        if (nftData.floor_price) {
          return `${ethFormat(nftData.floor_price)} ${getTokenUnit(nftData.blockchain)} ($${ethFormat(nftData.floor_price_usd)})`;
        }
        if (nftData.floor_eth_7d) {
          return `${ethFormat(nftData.floor_eth_7d)}
          ${getTokenUnit(nftData.blockchain)} ($${ethFormat(nftData.floor_price_usd)})`;
        }
        return '-';
      };
      const getMaxValue = (type) => {
        let averagePrice;
        let sevenDayAveragePrice;
        let thirtyDayAveragePrice;
        let oneDayAveragePrice;
        if (type === 'eth') {
          averagePrice = nft.average_price !== null ? nft.average_price : 0;
          sevenDayAveragePrice = nft.seven_day_average_price !== null ? nft.seven_day_average_price : 0;
          thirtyDayAveragePrice = nft.thirty_day_average_price !== null ? nft.thirty_day_average_price : 0;
          oneDayAveragePrice = nft.one_day_average_price !== null ? nft.one_day_average_price : 0;
        } else {
          averagePrice = nft.average_price_usd !== null ? nft.average_price_usd : 0;
          sevenDayAveragePrice = nft.seven_day_average_price_usd !== null ? nft.seven_day_average_price_usd : 0;
          thirtyDayAveragePrice = nft.thirty_day_average_price_usd !== null ? nft.thirty_day_average_price_usd : 0;
          oneDayAveragePrice = nft.one_day_average_price_usd !== null ? nft.one_day_average_price_usd : 0;
        }

        return Math.max(averagePrice, sevenDayAveragePrice, thirtyDayAveragePrice, oneDayAveragePrice);
      };

      setTitleSectionData({
        id: nft.contract_address,
        logo: nft.image_url || 'logo',
        name: nft.opensea_slug_contract_count > 1
          ? nft.contract_name || nft.name || nft.upper_name || '?'
          : nft.name || nft.upper_name || '?',
        collectionsName: nft.name || '?',
        symbol: nft.symbol,
        slug: nft.opensea_slug,
        contractCount: nft?.opensea_slug_contract_count,
        description: nft.description ? nft.description : 'Description coming soon',
        author: '',
        network: nft.blockchain,
        isVerified: nft.is_verified_on_opensea,
        links: {
          homepage: [nft?.external_url],
          telegram_channel_identifier: nft?.telegram_url,
          twitter_screen_name: nft?.twitter_username,
          chat_url: [nft?.discord_url],
          instagram: nft?.instagram_username,
          facebook_username: nft?.facebook_username,
          openSea: nft.opensea_slug,
        },
      });

      setMarketData({
        name: nft.name,
        NFTMarketData: {
          highest: `${getMaxValue('eth') ? ethFormat(getMaxValue('eth')) : 0} ${getTokenUnit(nft?.blockchain)} / ${(getMaxValue('usd'))
            ? `$${ethFormat(getMaxValue('usd'))}` : '-'}`,
          seven: nft.seven_day_average_price && nft.seven_day_average_price !== null
          && nft.seven_day_average_price !== 0
            ? `${ethFormat(nft.seven_day_average_price)} ${getTokenUnit(nft?.blockchain)} / ${nft.seven_day_average_price_usd
              ? `$${ethFormat(nft.seven_day_average_price_usd)}`
              : '-'}`
            : '-',
          floor: nft.floor_eth_7d && nft.floor_eth_7d !== null && nft.floor_eth_7d !== 0
            ? `${ethFormat(nft.floor_eth_7d)} ${getTokenUnit(nft?.blockchain)} / ${nft?.floor_eth_7d_usd
              ? `$${ethFormat(nft.floor_eth_7d_usd)}`
              : '-'}`
            : '-',
          ceiling: nft.ceiling_eth_7d && nft.ceiling_eth_7d !== null && nft.ceiling_eth_7d !== 0
            ? `${ethFormat(nft.ceiling_eth_7d)} ${getTokenUnit(nft?.blockchain)} / ${nft?.ceiling_eth_7d_usd
              ? `$${ethFormat(nft.ceiling_eth_7d_usd)}`
              : '-'}`
            : '-',
        },
        InfoData: [
          {
            title: 'Holders',
            value: nft.holder_count !== null ? dividerCommaFormat(nft.holder_count) : '-',
            growth: getGrowth(
              nft.holder_count,
              nft.previous_values?.holder_count,
              dividerCommaFormat(nft.previous_values?.holder_count),
            ),
          },
          {
            title: '# Items',
            value: getItems(nft.count),
            growth: getGrowth(
              nft.count,
              nft.previous_values?.count,
              getItems(nft.previous_values?.count),
              true,
            ),
          },
          ...(nft.one_day_sales !== null
            ? [
                {
                  title: '24h # Sales',
                  value: nft.one_day_sales,
                },
              ]
            : []),
          {
            title: '24h / 7d / Total Volume',
            value: (`
            ${nft.volume_eth_24h === null ? '0' : `${ethFormat(nft.volume_eth_24h)}`}
            / ${nft.volume_eth_7d === null ? '0' : `${ethFormat(nft.volume_eth_7d)}`}
            / ${nft.total_volume === null ? '0' : `${ethFormat(nft.total_volume)}`}
            ${getTokenUnit(nft.blockchain)}`),
          },
          ...(nft.one_day_average_price !== null
            ? [
                {
                  title: '24h Average',
                  value: nft.one_day_average_price === 0
                    ? '-'
                    : `${ethFormat(nft.one_day_average_price)} ${getTokenUnit(nft.blockchain)}`,
                },
              ]
            : []),
          {
            title: 'Monthly Active Addresses',
            value: nft.monthly_active_addresses !== null ? `${convertNumber(nft.monthly_active_addresses)}` : '0',
            growth: getGrowth(
              nft.monthly_active_addresses,
              nft.previous_values?.monthly_active_addresses,
              dividerCommaFormat(nft.previous_values?.monthly_active_addresses),
            ),
          },
          ...(nft.total_sales !== null
            ? [
                {
                  title: 'Total Sales',
                  value: convertNumber(nft.total_sales),
                },
              ]
            : []), 
        ],
        WidgetData: [
          {
            title: 'Floor Price',
            value: floorPrice(nft),
            icon: Price,
            growth: getGrowth(
              nft.floor_price ? nft.floor_price : nft.floor_eth_7d,
              nft.floor_price ? nft.previous_values?.floor_price : nft.previous_values?.floor_eth_7d,
              `${ethFormat(nft.floor_price
                ? nft.previous_values?.floor_price : nft.previous_values?.floor_eth_7d)} ${getTokenUnit(nft.blockchain)}`,
            ),
          },
          {
            title: 'Average Holdings',
            value: getItemsPerHolder(nft.count, nft.holder_count),
            growth: getGrowth(
              nft.holder_count !== 0 ? nft.count / nft.holder_count : 0,
              nft.previous_values?.holder_count !== undefined
              && nft.previous_values?.holder_count !== null
              && nft.previous_values?.count !== null
              && nft.previous_values?.count !== 0
              && nft.previous_values?.count !== undefined
                ? (nft.previous_values?.count || 0) / (nft.previous_values?.holder_count || 1) : 0,
              getItemsPerHolder(nft.previous_values?.count, nft.previous_values?.holder_count),
            ),
            icon: Item,
          },
          {
            title: 'Stickiness 7d / 30d',
            value: `${removeExcessZeros(nft.pct_held_over_7_days, 2) || '?'}% 
            / ${removeExcessZeros(nft.pct_held_over_30_days, 2) || '?'}%`,
            icon: Volume,
            info: 'Percentage of holders owning at least an NFT for at least 7/30 days',
            growth: [
              getGrowth(
                nft.pct_held_over_7_days,
                nft.previous_values?.pct_held_over_7_days,
                `${toMaxNDecimals(nft.previous_values?.pct_held_over_7_days)}%`,
              ),
              getGrowth(
                nft.pct_held_over_30_days,
                nft.previous_values?.pct_held_over_30_days,
                `${toMaxNDecimals(nft.previous_values?.pct_held_over_30_days)}%`,
              ),
            ],
          },
          {
            title: 'Avg. Hold Time ',
            value: getDays(nft.average_hold_time_days),
            icon: Holder,
            growth: getGrowth(
              nft.average_hold_time_days,
              nft.previous_values?.average_hold_time_days,
              getDays(nft.previous_values?.average_hold_time_days),
            ),
          },
        ],
      });

      setPages([
        {
          label: 'Audience Profile',
          component: (
            <InvestorProfile
              audienceProfileData={audienceProfileData}
              audienceProfileIsLoading={isAudienceProfileLoading || isAudienceProfileFetching}
              address={nft.contract_address}
              holders={audienceProfileData?.wallet_count}
              network={nft.blockchain}
              symbol={nft.symbol}
              canDisplayAi={canDisplayAi}
              aiOverview={aiOverview}
              isCustomContract={!!nft?.custom_contract}
            />),
        },
        {
          label: 'Audience Portfolio',
          component: (
            <InvestPortfolio
              address={nft.contract_address}
              holders={audienceProfileData?.wallet_count}
            />),
        },
        {
          label: ['current', 'relevant'].includes(filterOption) ? 'Holders' : 'List',
          component: (
            <Holders
              address={nft.contract_address}
              blockchain={nft.blockchain}
            />),
        },
        ...(!isOrdinalsOrFlow ? [{
          label: 'Dapp Usage',
          component: (<DappUsage holders={audienceProfileData?.wallet_count} />),
        }] : []),
        ...(!isOrdinalsOrFlow ? [{
          label: 'Centralized Exchanges',
          component: (<Exchanges holders={audienceProfileData?.wallet_count} />),
        }] : []),
        {
          label: 'Developer Activity',
          component: (<DevActivity
                        blockchain={network}
                        holders={audienceProfileData?.wallet_count}
                      />)
        },
        // { label: 'Items', component: <ComingSoon /> },
        // { label: 'News', component: <ComingSoon /> },
        // { label: 'Social', component: <ComingSoon /> },
        // { label: 'Contract', component: <ComingSoon /> },
        // { label: 'Competitors', component: <ComingSoon /> },
      ]);
    }
    if (nft && nft?.custom_contract) {
      setTitleSectionData({
        id: nft.custom_contract.contract_address,
        logo: nft.custom_contract.image_url || 'logo',
        name: nft.custom_contract.contract_name || '?',
        symbol: nft.custom_contract.symbol,
        slug: nft.custom_contract.slug,
        transferable: nft.custom_contract.transferable,
        contractCount: nft.custom_contract?.count || 0,
        description: nft.custom_contract.description ? nft.custom_contract.description : 'Description coming soon',
        network: nft.custom_contract.blockchain,
        isVerified: !!nft.custom_contract.slug,
        links: null,
        isCustomContract: true,
        maxSupply: nft?.custom_contract?.max_supply,
        isReady: nft?.custom_contract?.is_ready,
        id_obj: nft?.custom_contract?.id,
      });
    }
  }, [
    dispatch,
    isFetchingNFTProfile,
    isLoadingNFTProfile,
    nft, statsTrading,
    audienceProfileData,
    isAudienceProfileLoading,
    isAudienceProfileFetching,
    canDisplayAi,
    aiOverview,
  ]);

  useEffect(() => {
    if (searchParams.get('scroll') && navType === 'POP') {
      setTimeout(
        () => window.scrollTo({ top: +searchParams.get('scroll'), left: 0, behavior: 'smooth' }),
        100,
      );
    }
  }, [searchParams.get('scroll')]);

  useEffect(() => () => {
    dispatch(setTabPages([]));
    dispatch(resetSelectedAsset());
  }, [dispatch]);

  return (
    <>
      {!(isLoadingNFTProfile || isFetchingNFTProfile) && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {nft?.name || 'NFT'}
            {' '}
            Insights
            {' '}
            - Absolute Labs Platform
          </title>
        </Helmet>
      )}
      <SingleNFTTitle
        isAssetLoading={isLoadingNFTProfile
          || isFetchingNFTProfile}
        singleNftData={titleSectionData}
      />
      <div className="asset-section mt-3">
        <Market
          contractCount={titleSectionData?.contractCount}
          isAssetLoading={isLoadingNFTProfile
            || isFetchingNFTProfile}
          marketData={marketData.NFTMarketData}
          id={marketData.name}
          widgetData={marketData.WidgetData || [{}, {}, {}, {}, {}]}
          infoData={marketData.InfoData}
          chartData={statsTrading}
          isLoading={isLoadingNFTProfile
            || isFetchingNFTProfile
            || isStatsTradingLoading
            || isStatsTradingFetching}
          isReachLoading={isReachLoading
            || isReachFetching
            || isLoadingNFTProfile
            || isFetchingNFTProfile}
          holders={reach?.profile_count}
          reach={reach}
          exchange={exchangeRate}
          status={status}
          type="nft"
          isCustomContract={!!nft?.custom_contract}
          isReady={!nft?.custom_contract || nft?.custom_contract?.is_ready}
          testnet={nft?.custom_contract?.testnet}
          networks={[network]}
          canDisplayAi={canDisplayAi}
          name={marketData.name}
          blockchain={nft?.blockchain}
          aiOverview={marketAiOverview}
        />
      </div>
      {(!nft?.custom_contract || nft?.custom_contract?.is_ready)
      && (
        <>
          <Filters />
          <div className="asset-section m-o mt-3">
            <div className="title-gap asset-navigation">
              <ul className="nav nav-pills gap-2">
                {pages.map((item) => (
                  <li
                    role="presentation"
                    className="nav-item cursor-pointer"
                    key={uniqueId('tab')}
                    onClick={() => {
                      setSelectedTab(getPage(item.label));
                      setSearchParams({
                        ...Object.fromEntries([...searchParams]),
                        tab: item.label,
                        scroll: '',
                        page: '1',
                        orderBy: '',
                      });
                    }}
                  >
                    <Link
                      className={`nav-link ${item.label === selectedTab?.label ? 'active' : ''}`}
                      aria-current="page"
                      to={`${pathname}${search}`}
                    >
                      {item.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="dropdown-divider w-100" />
            {selectedTab?.component}
          </div>
        </>
      )}
    </>
  );
};

export default NFTDetails;
