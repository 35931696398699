import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectSubmitAlign,
  selectSubmitLabel,
  selectSubmitStyle,
  setSubmitAlign,
  setSubmitFullWidth,
  setSubmitLabel,
  setSubmitStyle,
} from '../../../../../../store/reducers/forms';
import { styleOptions, alignmentOptions } from './options';
import ModalSettings from '../../../Components/ModalSettings';
import SelectionButtons from '../../../../../../components/ui/SelectionButtons';
import styles from './SubmitSettings.module.scss';
import settingsStyles from '../../../Components/ModalSettings/ModalSettings.module.scss';
import Input from '../../../Components/ModalSettings/Input';

const SubmitSettings = () => {
  const dispatch = useDispatch();
  const label = useSelector(selectSubmitLabel);
  const style = useSelector(selectSubmitStyle);
  const align = useSelector(selectSubmitAlign);
  const [errors, setErrors] = useState({});

  return (
    <ModalSettings title="Submit Button Properties">
      <div className={styles.wrapper}>
        <div className={settingsStyles.title}>Style</div>
        <div className={styles.button_wrapper}>
          <SelectionButtons
            buttons={styleOptions}
            selectedValue={style}
            setSelectedValue={(value) => {
              dispatch(setSubmitStyle(value));
            }}
            width={84}
          />
          <SelectionButtons
            buttons={alignmentOptions}
            selectedValue={align}
            setSelectedValue={(value) => {
              dispatch(setSubmitAlign(value));
              dispatch(setSubmitFullWidth(value === 'full'));
            }}
            width={53}
          />
        </div>
      </div>

      <Input
        title="Label"
        value={label}
        placeholder="Type here"
        onChange={(e) => {
          const newVal = e.currentTarget.value;
          if (newVal.length > 30) {
            setErrors({ ...errors, label: 'Character limit reached' });
          } else {
            setErrors({ ...errors, label: '' });
            dispatch(setSubmitLabel(e.currentTarget.value));
          }
        }}
        maxLength={31}
        error={errors.label}
      />
    </ModalSettings>
  );
};

export default SubmitSettings;
