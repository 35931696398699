import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './FormSettings.module.scss';
import { selectActiveSettings, clearActiveSettings, setFonts, selectGlobalFont, selectHeadlineFont, selectParagraphFont, selectInputFont, selectButtonFont } from '../../../../store/reducers/forms';
import OverallSettings from './OverallSettings';

import ConnectWalletButtonSettings from './FieldSettings/ConnectWalletButton';
import HeadlineSettings from './FieldSettings/Headline';
import ButtonSettings from './FieldSettings/Button';
import CountrySettings from './FieldSettings/Country';
import ParagraphSettings from './FieldSettings/Paragraph';
import EmailSettings from './FieldSettings/Email';
import ImageSettings from './FieldSettings/Image';
import LanguageSettings from './FieldSettings/Language';
import NameSettings from './FieldSettings/Name';
import PhoneSettings from './FieldSettings/Phone';
import DiscordSettings from './FieldSettings/Discord';
import TwitterSettings from './FieldSettings/Twitter';
import TelegramSettings from './FieldSettings/Telegram';
import InstagramSettings from './FieldSettings/Instagram';
import QuestionTextSettings from './FieldSettings/QuestionText';
import OptInSettings from './FieldSettings/OptIn';
import { fontsApi } from '../../../../api/fonts';
import { injectFonts } from '../../../../utils/fonts';
import SubmitSettings from './FieldSettings/Submit';

const FormSettings = () => {
  const [settingsToDisplay, setSettingsToDisplay] = useState(null);
  const activeSettings = useSelector(selectActiveSettings);
  const globalFont = useSelector(selectGlobalFont);
  const headlineFont = useSelector(selectHeadlineFont);
  const paragraphFont = useSelector(selectParagraphFont);
  const inputFont = useSelector(selectInputFont);
  const buttonFont = useSelector(selectButtonFont);
  const dispatch = useDispatch();
  let timeoutId = useRef(null);

  const {
      currentData: fontsData,
    } = fontsApi.useGetFontsForTeamQuery();

  useEffect(() => {
    if (fontsData && fontsData.fonts?.length > 0) {
      injectFonts(fontsData.fonts);
    }
  }, [fontsData])

  useEffect(() => {
    if (!fontsData || fontsData.fonts?.length === 0) return;
    
    const usedFonts = [...new Set(
      [globalFont, headlineFont, paragraphFont, inputFont, buttonFont].filter(font => font !== null)
    )];

    const usedFontsData = fontsData.fonts.filter((fd) => usedFonts.includes(fd.name));
    dispatch(setFonts(usedFontsData));
  }, [
    fontsData,
    globalFont,
    headlineFont,
    paragraphFont,
    inputFont,
    buttonFont
  ])

  const settingsList = ({
    overall: <OverallSettings fontsData={fontsData} />,
    connectWallet: <ConnectWalletButtonSettings activeSettings={activeSettings} />,
    headline: <HeadlineSettings activeSettings={activeSettings} />,
    paragraph: <ParagraphSettings activeSettings={activeSettings} />,
    button: <ButtonSettings activeSettings={activeSettings} />,
    country: <CountrySettings activeSettings={activeSettings} />,
    email: <EmailSettings activeSettings={activeSettings} />,
    image: <ImageSettings activeSettings={activeSettings} />,
    language: <LanguageSettings activeSettings={activeSettings} />,
    name: <NameSettings activeSettings={activeSettings} />,
    phone: <PhoneSettings activeSettings={activeSettings} />,
    discord: <DiscordSettings activeSettings={activeSettings} />,
    twitter: <TwitterSettings activeSettings={activeSettings} />,
    telegram: <TelegramSettings activeSettings={activeSettings} />,
    instagram: <InstagramSettings activeSettings={activeSettings} />,
    questionText: <QuestionTextSettings activeSettings={activeSettings} />,
    optIn: <OptInSettings activeSettings={activeSettings} />,
    submit: <SubmitSettings />,
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      const wrapperEl = document.querySelector(`.${styles.wrapper}`);
      if (wrapperEl && !wrapperEl.contains(event.target)) {
        dispatch(clearActiveSettings());
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dispatch]);

  useEffect(() => {
    if (activeSettings) {
      // cancel the timeout if it's already running
      clearTimeout(timeoutId);
      timeoutId = null;
      setSettingsToDisplay(settingsList[activeSettings.type]);
    } else {
      timeoutId = setTimeout(() => {
        setSettingsToDisplay(null);
        timeoutId = null;
      }, 750);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [activeSettings]);

  return (
    <div
      className={`
      ${styles.wrapper}
      ${activeSettings ? '' : styles.closed}`}
      key={
        (settingsToDisplay?.props?.activeSettings?.field_id && activeSettings?.field_id
          && settingsToDisplay?.props?.activeSettings?.field_id !== activeSettings.field_id)
          ? activeSettings.field_id : null
      }
    >
      {settingsToDisplay}
    </div>
  );
};

export default FormSettings;
