import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { getCampaign } from '../../../store/reducers/campaign';
import { isCampaignValid } from '../helper';
import AISummary from '../AISummary';
import styles from './Footer.module.scss'

const Footer = ({ onSubmit, onCancel, isEditingPublishedCampaign }) => {
  const campaign = useSelector(getCampaign);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (campaign) {
      setIsValid(isCampaignValid(campaign));
    } else {
      setIsValid(false);
    }
  }, [campaign])

  return (
    <div className={`${styles.footer} d-flex justify-content-end align-items-center`}>
      <div className={styles.ai}>
        <AISummary />
      </div>
      <button
        type="button"
        className="outline-button"
        onClick={onCancel}
      >
        Cancel
      </button>
      <div className={styles.footer_save}>
        {!isEditingPublishedCampaign ? (
          <button
            type="submit"
            className="outline-blue-button"
            onClick={() => {
              onSubmit('Draft');
            }}
          >
            Save as Draft
          </button>
        ) : null}
        <button
          type="submit"
          className="regular-button"
          disabled={!isValid}
          onClick={() => {
            onSubmit('Active');
          }}
        >
          {isEditingPublishedCampaign ? 'Save and Publish Changes' : 'Publish Campaign'}
        </button>
      </div>
    </div>
  )
}

export default Footer
