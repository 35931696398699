import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { 
  selectFonts,
  selectGlobalFont,
  selectHeadlineFont,
  selectParagraphFont,
  selectInputFont,
  selectButtonFont,
} from "../../store/reducers/forms";
import { createWeightList } from "../../utils/fonts";

const useFontWeights = (fontType) => {
  const fontsData = useSelector(selectFonts);
  const globalFont = useSelector(selectGlobalFont);
  const headlineFont = useSelector(selectHeadlineFont);
  const paragraphFont = useSelector(selectParagraphFont);
  const inputFont = useSelector(selectInputFont);
  const buttonFont = useSelector(selectButtonFont);
  const [weights, setWeights] = useState([]);

  const font =
    fontType === "headline" ? headlineFont || globalFont :
    fontType === "paragraph" ? paragraphFont || globalFont :
    fontType === "input" ? inputFont || globalFont :
    fontType === "button" ? buttonFont || globalFont :
    globalFont;

  useEffect(() => {
    if (fontsData?.length > 0 && font) {
      const fontList = fontsData.filter((fd) => fd.name === font);
      const weightList = [...new Set(fontList.map((fd) => fd.weight))].sort((a, b) => a - b);
      setWeights(createWeightList(weightList));
    }
  }, [fontsData, font]);

  return weights;
};

export default useFontWeights;
