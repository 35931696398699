import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectButtonRadius, setButtonRadius } from '../../../../../../../store/reducers/forms';
import Slider from '../../../../../../../components/ui/Slider';
import settingsStyles from '../../../../Components/ModalSettings/ModalSettings.module.scss';
import styles from './ButtonRadius.module.scss';

const ButtonRadius = () => {
  const dispatch = useDispatch();
  const buttonRadius = useSelector(selectButtonRadius);

  return (
    <div className={styles.wrapper}>
      <div className={settingsStyles.title}>Button Corner Radius</div>
      <div className={styles.slider}>
        <Slider value={buttonRadius} setValue={(val) => dispatch(setButtonRadius(val))} max={22} unit='px' />
      </div>
    </div>
  )
}

export default ButtonRadius
